<template>
    <div class="row">
        <div class="col-12">
            <errors :error_bag="error_bag"></errors>
        </div>
        <div class="col-md-6 col-12">
            <div v-if="issue == undefined">
                <h2>Details</h2>
                <div class="form-floating my-4" v-show="projects.length > 1">
                    <select id="issue-project-affected-input" class="form-select focus-ring focus-ring-primary" name="project_id" v-model="selectedProjectId">
                        <option v-for="project in projects" :value="project.id" :key="project.id">{{ project.name }}</option>
                    </select>
                    <label for="issue-project-affected-input">Project*</label>
                </div>
                <div class="form-floating my-4" v-show="filteredProjectElements.length > 1">
                    <select id="issue-element-affected-input" class="form-select focus-ring focus-ring-primary" name="element_affected_id">
                        <option v-for="element in filteredProjectElements" :value="element.id" :key="element.id">{{ element.name }}</option>
                    </select>
                    <label for="issue-element-affected-input">Element affected*</label>
                </div>
                <div class="my-4">
                    <div class="input-group">
                        <div class="form-floating">
                            <input id="issue-jira_summary-input" name="jira_summary" placeholder="Ticket title*" class="form-control focus-outline focus-outline-primary focus-ring focus-ring-primary" :value="jira_summary">
                            <label for="issue-jira_summary-input">Ticket title*</label>
                        </div>
                    </div>
                    <small class="my-1">What is the issue? 20 words max</small>
                </div>
                <div class="form-floating">
                    <textarea id="issue-full-description-input" name="jira_description" class="form-control" placeholder="Full Description*" rows="8" :value="jira_description"></textarea>
                    <label for="issue-full-description-input">Full Description*</label>
                    <small class="my-1">Please explain the issue in more detail. You can upload images in the “Evidence” section of this page, and include URLs below.</small>
                </div>
            </div>
            <div v-if="issue != undefined">
                <h2>Add Update</h2>
                <div class="form-floating">
                    <textarea id="issue-update-input" name="jira_description" class="form-control" placeholder="Update*" rows="8" :value="jira_update"></textarea>
                    <label for="issue-update-input">Update*</label>
                    <small class="my-1">Please detail any information that you’d like to add as an update. This will be added onto the original task as a comment, which can be viewed by Project Managers and Developers.</small>
                </div>
            </div>
            <div class="my-4">
                <div class="input-group">
                    <div class="form-floating">
                        <input id="issue-url-input" name="url" placeholder="URL, if applicable" class="form-control focus-outline focus-outline-primary focus-ring focus-ring-primary" :value="url">
                        <label for="issue-url-input">URL, if applicable</label>
                        <small v-if="issue != undefined" class="my-1">Please leave blank if the same URL as listed in the original Ticket, above</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12">
            <h2>Evidence</h2>
            <div class="my-3">
                <label class="form-label">Media</label>
                <Uploader
                    :server="temporary_media_upload_route"
                    :is-invalid="false"
                />
                <small class="my-1">Please annotate images (eg. using software like Greenshot) to ensure issues are as clearly highlighted as possible.</small>
            </div>
            <div class="my-4">
                <multiple-file-input/>
            </div>
            <h2>Impact</h2>
            <small v-if="issue != undefined" class="my-1">Leave this section blank if this is unchanged from the first reporting of this ticket.</small>
            <p class="mt-4">How many {{ filteredTerm }} are directly affected?</p>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="impact_count" id="issue-input-count-input-none" value="none" :checked="impact_count == 'none'">
                <label class="form-check-label" for="issue-input-count-input-none">None</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="impact_count" id="issue-input-count-input-one" value="one" :checked="impact_count == 'one'">
                <label class="form-check-label" for="issue-input-count-input-one">One</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="impact_count" id="issue-input-count-input-multiple" value="multiple" :checked="impact_count == 'multiple'">
                <label class="form-check-label" for="issue-input-count-input-multiple">Multiple</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="impact_count" id="issue-input-count-input-all" value="all" :checked="impact_count == 'all'">
                <label class="form-check-label" for="issue-input-count-input-all">All</label>
            </div>
            <div class="my-4">
                <div class="input-group">
                    <div class="form-floating">
                        <input id="issue-impact-input" name="impact" :placeholder="filteredTermTitle +  ' affected'" class="form-control focus-outline focus-outline-primary focus-ring focus-ring-primary" :value="impact">
                        <label for="issue-impact-input">{{ filteredTermTitle }} affected</label>
                    </div>
                </div>
                <small class="my-1">Please list all {{ filteredTerm }} that are affected by this issue.</small>
            </div>
            <div>
                <h2>Priority</h2>
                <p>Is your opinion, what priority is this issue?</p>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="priority" id="issue-priority-input-low" value="Lowest" :checked="priority == 'Lowest' || priority == ''">
                    <label class="form-check-label" for="issue-priority-input-low">Low <small>(minor impact on the business, such as minor feature requests or cosmetic issues)</small></label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="priority" id="issue-priority-input-medium" value="Low" :checked="priority == 'Low'">
                    <label class="form-check-label" for="issue-priority-input-medium">Medium <small>(moderate impact on the business, such as a bug affecting a key feature or general use of the system)</small></label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="priority" id="issue-priority-input-urgent" value="High" :checked="priority == 'High'">
                    <label class="form-check-label" for="issue-priority-input-urgent">Urgent <small>(significant impact on the business, such as a critical, which has multiple negative effects in the system)</small></label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="priority" id="issue-priority-input-emergency" value="Highest" :checked="priority == 'Highest'">
                    <label class="form-check-label" for="issue-priority-input-emergency">Emergency <small>(severe impact on the business, such as a widespread outage affecting many users or a security breach)</small></label>
                </div>
            </div>
        </div>
        <div class="col-12 text-end">
            <button v-if="issue == undefined" id="issues-create-submit-button" class="align-self-end btn-secondary btn-lg btn text-nowrap rounded-0 shadow" type="submit"><i class="fa-solid fa-floppy-disk me-1"></i> Save Ticket</button>
            <button v-if="issue != undefined" id="issues-create-submit-button" class="align-self-end btn-secondary btn-lg btn text-nowrap rounded-0 shadow" type="submit"><i class="fa-solid fa-floppy-disk me-1"></i> Save Update</button>
        </div>
    </div>
</template>

<script>
    import multipleFileInput from './multiple-file-input.vue';
    import errors from './errors.vue';

    export default {
        name: "issue-details-form",
        data() {
            return {
                selectedProjectId: null,
            }
        },
        mounted() {
            this.selectedProjectId = this.selected_project_id
            this.selectedProjectElementId = this.selected_project_element_id
        },
        props: [
            'error_bag',
            'impact',
            'impact_count',
            'issue',
            'jira_description',
            'jira_summary',
            'jira_update',
            'priority',
            'projects',
            'project_elements',
            'selected_project_id',
            'selected_project_element_id',
            'temporary_media_upload_route',
            'uploaderUrl',
            'url'
        ],
        methods: {},
        computed: {
            filteredProjectElements() {
                
                if (this.selectedProjectId != '' && this.project_elements[this.selectedProjectId] != undefined) {
                    return this.project_elements[this.selectedProjectId];
                }

                return [];
            },
            filteredTerm() {
                
                if (this.selectedProjectId != '') {
                    var selectedProjectId = this.selectedProjectId;

                    var project = this.projects.find(function (element) {
                        return element.id == selectedProjectId;
                    });

                    if (project) {
                        return project.plural_terminology
                    }
                }

                return '';
            },
            filteredTermTitle() {
                if (this.filteredTerm[0] != undefined) {
                    return this.filteredTerm[0].toUpperCase() + this.filteredTerm.slice(1)
                }
            }
        },
        components: {
            'multiple-file-input': multipleFileInput,
            'errors': errors
        }
    };
</script>